import React, { useState, useEffect } from "react";
import algoliasearch from 'algoliasearch/lite';
import parseResultsUrl from "../../../utils/property-results/parseResultsUrl";
import generateFiltersfromPageData from "../../../utils/property-results/generateFiltersfromPageData";
// import createResultsUrl from "../../../utils/property-results/createResultsUrl";
import SearchResultPage from "../../../templates/search-results-template";

const SearchResultResiMap = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location

    const [free_text_search, setFreeTextSearch] = useState([]);
    const [h1_areas, setH1Areas] = useState([]);


    const [algoliadata, getAlgoliadata] = useState([]);
    // const [mysortbyoption, setMysortbyoption] = useState('');
    // const [mypageoption, setMypageoption] = useState('');

    /* ========= Local Cache =========== */
    let local_storage_data = typeof window !== 'undefined' &&  localStorage.getItem('algolia_data') ? JSON.parse(localStorage.getItem('algolia_data')) : [];
    const [local_cache, setLocalCache] = useState(local_storage_data);

    //the current page data are lives here
    const page_url_data = parseResultsUrl(props.location)

    // you can make default search page results filters here
    const myalgoliafilters = generateFiltersfromPageData(free_text_search, page_url_data['departmentVal'], page_url_data['searchtypeVal'], page_url_data['statusVal'], page_url_data['statusenableDefaultVal'], page_url_data['areaVal'], page_url_data['bedVal'], page_url_data['minpriceVal'], page_url_data['maxpriceVal'], page_url_data['buildingval'], page_url_data['officeVal'], page_url_data['energyVal'], page_url_data['agreedVal'], page_url_data['mustincludeVal'], page_url_data['minsizeVal'], page_url_data['maxsizeVal'])

    // navigate to pages based on filters


    useEffect(() => {
        getAlgoliaResutls(myalgoliafilters);
    }, [props.location]);

    const getAlgoliaResutls = (myalgoliafilters) => {
        // lets run algolia search query to fetch hits, stats and number of pages
        const client = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_API_KEY);
        const index = client.initIndex(page_url_data['sortVal'] ? page_url_data['sortVal'] : process.env.GATSBY_ALGOLIA_INDEX_NAME);
        //const index = client.initIndex(process.env.GATSBY_ALGOLIA_INDEX_NAME);

        var officeVal_length = page_url_data['officeVal'].length
        var officeID = officeVal_length > 0 ? page_url_data['officeVal'].toString() : ''

        var area_name_length = page_url_data['areaVal'].length
        var area_name = officeID ? '' : area_name_length == 1 ? page_url_data['areaVal'].toString() : ''
        if(free_text_search.length > 0) {
            area_name = area_name
        }
        else {
            area_name = area_name
        }


        /* ========= Local Cache =========== */
        let path_name = typeof window !== 'undefined' ? window.location.href : ''
        var expTime = new Date().setMinutes(new Date().getMinutes() + 5);
        let filter_local_cache = filterLocalCache(path_name);

        if(filter_local_cache?.length > 0){
            //console.log("algoliadata 1", filter_local_cache)
            let get_data = filter_local_cache[0];
            let get_data_pathname = get_data[path_name]
            //console.log("get_data ==>", get_data_pathname);

            if(get_data_pathname?.total > 0){
                getAlgoliadata(get_data_pathname)
            } else{
                getAlgoliadata([])
            }
            
        } else{

            index.search((area_name_length == 1 && area_name != true && area_name != 'ireland' ? area_name : ''), {
                // similarQuery: area_name_length == 1 && area_name != true && area_name != 'ireland' ? area_name : '',
                filters: myalgoliafilters,
                page: (page_url_data['pageVal'] - 1),
                hitsPerPage: (page_url_data['layoutVal'] ? 1000 : 15)
            }).then(({ nbHits, page, hitsPerPage, nbPages, hits }) => {
                /* ========= Local Cache expTime =========== */
                var myArray = { "total": nbHits, "current_page_number": page, "hitsPerPage": hitsPerPage, "number_of_pages": nbPages, "hits": hits, expTime: expTime };
                getAlgoliadata(myArray)

                /* ========= Local Cache =========== */
                setLocalCache([...local_cache, {[path_name]: myArray}], () => localStorage.setItem('algolia_data', JSON.stringify(local_cache)));
            });

        }
    }

    /* ========= Local Cache =========== */
    const filterLocalCache = (path_name) => {
        let get_local_cache = local_cache;
        let filter_local_cache = [];
        // filter_local_cache = get_local_cache.length > 0 && get_local_cache.filter(key => key[path_name]);

        if(get_local_cache?.length > 0){
            let remove_local_cache =  get_local_cache?.filter(key => Object.values(key)[0]?.expTime > (new Date().getTime()))

            setLocalCache(remove_local_cache)
            localStorage.setItem('algolia_data', JSON.stringify(remove_local_cache))

            filter_local_cache = remove_local_cache?.length > 0 && remove_local_cache?.filter(key => key[path_name]);
        }

        //console.log("algoliadata 2", path_name, this.state.local_cache, filter_local_cache)

        return filter_local_cache;
    }

    return (
        <React.Fragment>
            <SearchResultPage
                {...algoliadata}
                pcategorytype="commercial"
                ptype={page_url_data['searchtypeVal']}
                ptypetag="for-sale"
                pstatustype="For Sale"
                fullpathone={fullpathone}
                fullpathname={fullpath}
                setFreeTextSearch={setFreeTextSearch}
                free_text_search={free_text_search}
                page_url_data = {page_url_data}
                h1_areas={h1_areas}
                setH1Areas={setH1Areas}
                {...props}
            />
        </React.Fragment>
    );
};

export default SearchResultResiMap;